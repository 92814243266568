import React, { Component } from 'react';
import { DropDownList, MultiSelect } from '@progress/kendo-react-dropdowns';
import { Input, NumericTextBox } from '@progress/kendo-react-inputs';

class SatExECForm extends Component {

	setInitialValues() {
		this.setState(this.props.initialValues);
		this.setState(this.props.options);
	}

	itemRender(li, itemProps) {
		const itemChildren = <div> {li.props.children} <div style={{ color: "#00F" }}> {itemProps.dataItem.device.name} [{itemProps.dataItem.port}]</div> </div>;
		return React.cloneElement(li, li.props, itemChildren);
	}

	extractItemRender(li, itemProps) {
		const itemChildren = <div> {itemProps.dataItem.description} [{itemProps.dataItem.unit && itemProps.dataItem.unit}] </div>;
		return React.cloneElement(li, li.props, itemChildren);
	}

	graphTypes = {
		line: { text: 'Line', value: 'line' },
		column: { text: 'Bar', value: 'column' }
	}

	render() {
		return (
			<div className='modal-form-inputs-wrap'>
				<div className="modal-form-inputs-wrap-row">
					<MultiSelect key='temp-select'
						listNoDataRender={this.props.loadingSensors ? this.props.noData : null}
						name='temp_sensor'
						required={true}
						label={this.props.intl.formatMessage({ id: 'app.chart.etoForm.temp', defaultMessage: 'TEMP' })}
						data={this.props.sensors}
						value={this.props.graph.variables.temp.sensor}
						textField='name'
						dataItemKey='id'
						itemRender={this.itemRender}
						onChange={this.props.handleChange}
						filterable={true}
						onFilterChange={this.props.handleFilterChange}
						style={{ height: 'auto' }}
					/>
					<DropDownList
						key='temp-extract-select'
						name='temp_extract'
						required={true}
						label={this.props.intl.formatMessage({ id: 'app.chart.etoForm.tempExtract', defaultMessage: 'TEMP SENSOR EXTRACT' })}
						data={this.props.extracts}
						value={this.props.graph.variables.temp.extract}
						textField='description'
						itemRender={this.extractItemRender}
						dataItemKey='id'
						onChange={this.props.handleChange}
						filterable
						onFilterChange={this.props.handleExtractFilterChange}
					/>
				</div>
				<div className="modal-form-inputs-wrap-row">
					<Input
						// required={true}
						defaultValue={'x'}
						name='temp_formula'
						label={this.props.intl.formatMessage({ id: 'app.chart.etcRawForm.tempFormula', defaultMessage: 'TEMP FORMULA' })}
						value={this.props.graph.variables.temp_formula}
						onChange={this.props.handleChange} />
				</div>
				<div className="modal-form-inputs-wrap-row">
					<MultiSelect
						listNoDataRender={this.props.loadingSensors ? this.props.noData : null}
						key='ec-select'
						name='ec_sensor'
						required={true}
						label={this.props.intl.formatMessage({ id: 'app.chart.satExEcForm.electricConductivity', defaultMessage: 'ELECTRIC CONDUCTIVITY' })}
						data={this.props.sensors}
						value={this.props.graph.variables.ec.sensor}
						textField='name'
						dataItemKey='id'
						itemRender={this.itemRender}
						onChange={this.props.handleChange}
						filterable={true}
						onFilterChange={this.props.handleFilterChange}
						style={{ height: 'auto' }}
					/>
					<DropDownList
						key='ec-extract-extract'
						name='ec_extract'
						required={true}
						label={this.props.intl.formatMessage({ id: 'app.chart.satExEcForm.ecExtract', defaultMessage: 'EC EXTRACT' })}
						data={this.props.extracts}
						value={this.props.graph.variables.ec.extract}
						textField='description'
						itemRender={this.extractItemRender}

						dataItemKey='id'
						onChange={this.props.handleChange}
						filterable
						onFilterChange={this.props.handleExtractFilterChange}
					/>
				</div>
				<div className="modal-form-inputs-wrap-row">
					<Input
						// required={true}
						defaultValue={'x'}
						name='ec_formula'
						label={this.props.intl.formatMessage({ id: 'app.chart.etcRawForm.ecFormula', defaultMessage: 'EC FORMULA' })}
						value={this.props.graph.variables.ec_formula}
						onChange={this.props.handleChange} />
				</div>
				<div className="modal-form-inputs-wrap-row">
					<MultiSelect
						listNoDataRender={this.props.loadingSensors ? this.props.noData : null}
						key='radiation-select'
						name='vwc_sensor'
						required={true}
						label={this.props.intl.formatMessage({ id: 'app.chart.satExEcForm.vwc', defaultMessage: 'VWC' })}
						data={this.props.sensors}
						value={this.props.graph.variables.vwc.sensor}
						textField='name'
						dataItemKey='id'
						itemRender={this.itemRender}
						onChange={this.props.handleChange}
						filterable={true}
						onFilterChange={this.props.handleFilterChange}
						style={{ height: 'auto' }} />
					<DropDownList
						key='vwc-extract-select'
						name='vwc_extract'
						required={true}
						label={this.props.intl.formatMessage({ id: 'app.chart.satExEcForm.vwcExtract', defaultMessage: 'VWC EXTRACT' })}
						data={this.props.extracts}
						value={this.props.graph.variables.vwc.extract}
						textField='description'
						itemRender={this.extractItemRender}

						dataItemKey='id'
						onChange={this.props.handleChange}
						filterable
						onFilterChange={this.props.handleExtractFilterChange}
					/>
				</div>

				<div className="modal-form-inputs-wrap-row">
					<Input
						// required={true}
						defaultValue={'x'}
						name='vwc_formula'
						label={this.props.intl.formatMessage({ id: 'app.chart.etcRawForm.VWCFormula', defaultMessage: 'VWC FORMULA' })}
						value={this.props.graph.variables.vwc_formula}
						onChange={this.props.handleChange} />
				</div>
				<div className="modal-form-inputs-wrap-row">
					<MultiSelect
						listNoDataRender={this.props.loadingSensors ? this.props.noData : null}
						key='wind-select'
						name='permitivity_sensor'
						required={true}
						label={this.props.intl.formatMessage({ id: 'app.chart.satExEcForm.permitivity', defaultMessage: 'PERMITIVITY' })}
						data={this.props.sensors}
						value={this.props.graph.variables.permitivity.sensor}
						textField='name'
						dataItemKey='id'
						itemRender={this.itemRender}
						onChange={this.props.handleChange}
						filterable={true}
						onFilterChange={this.props.handleFilterChange}
						style={{ height: 'auto' }} />
					<DropDownList
						key='permitivity-extract-select'
						name='permitivity_extract'
						required={true}
						label={this.props.intl.formatMessage({ id: 'app.chart.satExEcForm.permitivityExtract', defaultMessage: 'PERMITIVITY EXTRACT' })}
						data={this.props.extracts}
						value={this.props.graph.variables.permitivity.extract}
						textField='description'
						itemRender={this.extractItemRender}

						dataItemKey='id'
						onChange={this.props.handleChange}
						filterable
						onFilterChange={this.props.handleExtractFilterChange}
					/>
				</div>
				<div className="modal-form-inputs-wrap-row">
					<Input
						// required={true}
						defaultValue={'x'}
						name='permitivity_formula'
						label={this.props.intl.formatMessage({ id: 'app.chart.etcRawForm.permitivityFormula', defaultMessage: 'PERMITIVITY FORMULA' })}
						value={this.props.graph.variables.permitivity_formula}
						onChange={this.props.handleChange} />
				</div>
				<div className="modal-form-inputs-wrap-row">
					<NumericTextBox
						name='offset'
						required={true}
						key='offset-input'
						label={this.props.intl.formatMessage({ id: 'app.chart.satExEcForm.offset', defaultMessage: 'OFFSET' })}
						value={this.props.graph.variables.offset}
						onChange={this.props.handleChange}
						format='n2' />
					<NumericTextBox
						name='saturation'
						required={true}
						key='saturation-input'
						label={this.props.intl.formatMessage({ id: 'app.chart.satExEcForm.saturation', defaultMessage: 'SATURATION' })}
						value={this.props.graph.variables.saturation}
						onChange={this.props.handleChange}
						format='n2' />
				</div>
				<div className="modal-form-inputs-wrap-row">
					<DropDownList
						label={this.props.intl.formatMessage({ id: 'app.chart.rawSensorForm.chartType', defaultMessage: 'CHART TYPE' })}
						name='_type'
						data={[{ text: 'Line', value: 'line' }, { text: 'Bar', value: 'column' }]}
						textField='text'
						dataItemKey='value'
						value={this.graphTypes[this.props.graph._type]}
						onChange={this.props.handleChange} />
					<Input
						type='color'
						label={this.props.intl.formatMessage({ id: 'app.chart.rawSensorForm.color', defaultMessage: 'COLOR' })}
						name='color'
						// data={this.state.color}
						value={this.props.graph.color}
						onChange={this.props.handleChange} />
				</div>
				<div className="modal-form-inputs-wrap-row">
					<Input
						required={true}
						name='label'
						label={this.props.intl.formatMessage({ id: 'app.chart.rawSensorForm.label', defaultMessage: 'LABEL' })}
						value={this.props.graph.label}
						onChange={this.props.handleChange} />
					<Input
						name='axis_min'
						label={this.props.intl.formatMessage({ id: 'app.chart.rawSensorForm.axisMin', defaultMessage: 'AXIS MIN' })}
						value={this.props.graph.axis_min !== null ? this.props.graph.axis_min : ''}
						onChange={this.props.handleChange} />
				</div>
				<div className="modal-form-inputs-wrap-row">
					<Input
						name='axis_max'
						label={this.props.intl.formatMessage({ id: 'app.chart.rawSensorForm.axisMax', defaultMessage: 'AXIS MAX' })}
						value={this.props.graph.axis_max !== null ? this.props.graph.axis_max : ''}
						onChange={this.props.handleChange} />
					<Input
						required={true}
						name='y_axis_legend'
						label={this.props.intl.formatMessage({ id: 'app.chart.rawSensorForm.yAxisLegend', defaultMessage: 'Y AXIS LEGEND' })}
						value={this.props.graph.y_axis_legend}
						onChange={this.props.handleChange} />
				</div>
			</div>
		);
	}
}

export default SatExECForm;