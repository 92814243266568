import React, { useState } from 'react';
import { Grid as KGrid, GridColumn as Column, GridToolbar, GridNoRecords } from '@progress/kendo-react-grid';
import { orderBy, filterBy } from '@progress/kendo-data-query';
import '@progress/kendo-theme-material';
import { GridColumnMenuFilter } from "@progress/kendo-react-grid";
import { useIntl } from 'react-intl';


function GridContainer(props) {

    return (
        <div className="mdl-grid mdl-grid--no-spacing station-grid--container" style={{ justifyContent: 'center' }}>

            <div className="mdl-cell mdl-cell--12-col">
                <div className='mdl-grid'>
                    {props.children}
                </div>
            </div>

        </div>
    );
}

function ColumnMenu(props) {
    return (
        <div>
            <GridColumnMenuFilter {...props} expanded={true} />
        </div>
    );
}

function CustomHeader(props) {
    return (
        <a onClick={props.onClick}>
            <span>
                <span
                    style={{ color: 'black', fontSize: '1.1em' }}
                >
                    {props.title}
                </span>
                {props.children}
            </span>
        </a>
    )
}

function GraphGrid(props) {

    const [sort, setSort] = useState([]);
    const [filter, setFilter] = useState([]);
    const intl = useIntl();

    return (
        <div ref={props.graphsRef} className='mdl-cell mdl-grid--no-spacing mdl-cell--12-col mdl-cell--8-col-tablet mdl-cell--4-col-phone mdl-shadow--2dp graph-grid'>
            <KGrid
                data={filterBy(orderBy(props.data, sort), filter)}
                scrollable={'scrollable'}
                className='!h-[600px]'
                sortable
                sort={sort}
                onSortChange={e => setSort(e.sort)}
                onFilterChange={e => setFilter(e.filter)}
            >
                <GridNoRecords>
                    {intl.formatMessage({ id: 'app.admin.graph_grid.no_data', defaultMessage: 'No Records Available' })}
                </GridNoRecords>
                <GridToolbar>
                    <div className='mdl-grid mdl-grid--no-spacing mdl-cell--12-col'>
                        <div className='mdl-cell mdl-cell--4-col ag-grid-title'>
                            {intl.formatMessage({ id: 'app.admin.graph_grid.graphs', defaultMessage: 'Graphs' })}
                        </div>
                        <div className='ag-grid-link-button--ctn mdl-cell mdl-cell--8-col' >
                            <button className='graph-grid-toolbar-btn' onClick={(e) => props.setData(e, 'All')}>{intl.formatMessage({ id: 'app.admin.graph_grid.all', defaultMessage: 'All' })}</button>
                            <button className='graph-grid-toolbar-btn' onClick={(e) => props.setData(e, 'Stress severo')}>{intl.formatMessage({ id: 'app.admin.graph_grid.severe', defaultMessage: 'Severe' })}</button>
                            <button className='graph-grid-toolbar-btn' onClick={(e) => props.setData(e, 'Inicia stress')}>{intl.formatMessage({ id: 'app.admin.graph_grid.start', defaultMessage: 'Start' })}</button>
                            <button className='graph-grid-toolbar-btn' onClick={(e) => props.setData(e, 'Adecuado')}>{intl.formatMessage({ id: 'app.admin.graph_grid.optimal', defaultMessage: 'Optimal' })}</button>
                            <button className='graph-grid-toolbar-btn' onClick={(e) => props.setData(e, 'Exceso de agua')}>{intl.formatMessage({ id: 'app.admin.graph_grid.excess', defaultMessage: 'Excess' })}</button>
                        </div>
                    </div>

                </GridToolbar>
                <Column className='!overflow-ellipsis !whitespace-nowrap' title={intl.formatMessage({ id: 'app.admin.graph_grid.user', defaultMessage: 'User' })} field='chart.user.username' headerCell={CustomHeader} columnMenu={ColumnMenu} />
                <Column className='!overflow-ellipsis !whitespace-nowrap' title={intl.formatMessage({ id: 'app.admin.graph_grid.chart', defaultMessage: 'Chart' })} field='chart.name' headerCell={CustomHeader} columnMenu={ColumnMenu} />
                <Column className='!overflow-ellipsis !whitespace-nowrap' title={intl.formatMessage({ id: 'app.admin.graph_grid.legend', defaultMessage: 'Legend' })} field='label' headerCell={CustomHeader} columnMenu={ColumnMenu} />
                <Column title={intl.formatMessage({ id: 'app.admin.graph_grid.value', defaultMessage: 'Value' })} field='last_value.1' filter={'numeric'} headerCell={CustomHeader} columnMenu={ColumnMenu} />
            </KGrid>
        </div>
    );
}


export default GraphGrid;
export { GridContainer };