import React, { useState, useEffect, useRef } from 'react';
import { Grid as KGrid, GridColumn as Column, GridToolbar, GridNoRecords } from '@progress/kendo-react-grid';
import { useIntl } from 'react-intl';
import { orderBy, filterBy } from '@progress/kendo-data-query';
import { GridCell } from '@progress/kendo-react-grid';
import '@progress/kendo-theme-material';
import { getAuthToken, BASEURI } from '../utils';
import { formatDate } from '../utils';
import { MultiSelect, DropDownList } from '@progress/kendo-react-dropdowns';
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import Modal from '../general/Modal';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Input } from '@progress/kendo-react-inputs';
import EditUserModal from './EditUserModal';



function GridContainer(props) {

    return (
        <div className="mdl-grid mdl-grid--no-spacing station-grid--container" style={{ justifyContent: 'center' }}>

            <div className="mdl-cell mdl-cell--12-col">
                <div className='mdl-grid'>
                    {props.children}
                </div>
            </div>

        </div>
    );
}
export { GridContainer };

function CommandCell({ edit }, intl) {
    return props => {
        // render() {
        const { dataItem } = props;
        return (
            <td className="k-command-cell !w-[100px]">
                <button
                    className="k-primary k-button k-button-md k-button-solid k-button-solid-base k-rounded-md k-grid-edit-command"
                    onClick={() => edit(dataItem)}
                >
                    {intl.formatMessage({ id: "app.admin.gridUser.view", defaultMessage: 'View' })}
                </button>
            </td>

        );
        // }
    }
};
// the must be handled with react-int Caution!!!
function DateCell({ dataItem }) {
    return (
        <td>
            {dataItem.date ? formatDate(dataItem.date) :
                dataItem.user ? dataItem.user.last_login ? formatDate(dataItem.user.last_login) : '' : ""}
        </td>
    );

}

function AgentCell({ allAgents, handleAgentsChange, selectedAgents }) {

    return props => {
        //  render(){
        const { dataItem } = props;
        return (
            dataItem.inEdit ?
                <td>
                    <MultiSelect
                        name='agents'
                        data={allAgents}
                        textField='name'
                        value={selectedAgents}
                        onChange={handleAgentsChange}
                        style={{ height: 'auto' }}
                    />
                </td>
                :
                <td>
                    {
                        dataItem.agents ?
                            dataItem.agents.map((agent) => {
                                return agent.id && <p key={agent.id}>{agent.name}</p>
                            }) : ""
                    }
                </td>
        );
        // }
    }
}

function UserTypeCell(props) {
    function handleChange(event) {
        props.onChange({
            dataItem: dataItem,
            field: props.field,
            syntheticEvent: event.syntheticEvent,
            value: event.value.value
        })

    }

    const { dataItem } = props;
    const data = [
        { value: true, text: "ORG" },
        { value: false, text: "REG" }
    ];
    return (
        dataItem.inEdit ?
            <td>
                <DropDownList
                    name="is_organization"
                    data={data}
                    textField={'text'}
                    dataItemKey={'value'}
                    onChange={handleChange}
                    value={dataItem.is_organization ? data[0] : data[1]}
                />
            </td> :
            <td>
                {dataItem.is_organization ? 'ORG' : 'REG'}
            </td>
    );
}

function UserTypeFilterCell(props) {

    const data = [
        { value: true, text: "ORG" },
        { value: false, text: "REG" }
    ];

    function handleChange(e) {
        props.onChange({
            value: e.target.value.value,
            operator: e.target.value.value !== null ? 'eq' : '',
            syntheticEvent: e.syntheticEvent
        })
    }

    function clear(e) {
        e.preventDefault();
        props.onChange({
            value: null,
            operator: '',
            syntheticEvent: e
        });
    }

    return <div className='k-filtercell'>
        <div className='k-filtercell-wrapper'>
            <DropDownList
                className='filter-drop'
                data={data}
                value={props.value !== null ? props.value ? data[0] : data[1] : props.value}
                textField={'text'}
                dataItemKey={'value'}
                defaultItem={{ value: null, text: 'All' }}
                onChange={handleChange}
            />
            <button
                className='k-button k-button-md k-button-solid k-button-solid-base k-rounded-md k-button-icon k-clear-button-visible'
                title='Clear'
                disabled={props.value === null}
                onClick={clear}
            >
                {props.value === null ?
                    <span className='k-icon k-i-filter' />
                    :
                    <span className='k-icon k-i-filter-clear' />
                }
            </button>
        </div>
    </div>
}

function OrganizationCell({ data, ...props }) {

    function handleChange(event) {
        props.onChange({
            dataItem: dataItem,
            field: 'organization',
            syntheticEvent: event.syntheticEvent,
            value: event.value.id
        })

    }
    const { dataItem } = props;
    return (
        dataItem.inEdit ?
            <td>
                <DropDownList
                    name="organization"
                    data={data}
                    textField={'user.username'}
                    dataItemKey={'id'}
                    onChange={handleChange}
                    value={dataItem.value}
                />
            </td> :
            <td>
                {(dataItem.organization && dataItem.organization.user) && dataItem.organization.user.username}
            </td>
    );
}

function UserGrid(props) {
    const [sort, setSort] = useState([]);
    const [filter, setFilter] = useState([]);
    const [skip, setSkip] = useState(0);
    const [loading, setLoading] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [pageSize, setPageSize] = useState(15);
    const [users, setUsers] = useState({
        all: [],
        today: [],
        thisMonth: [],
        inactive: []
    });
    const [agents, setAgents] = useState([]);
    const [viewingUser, setViewingUser] = useState({});
    const [userGridData, setUserGridData] = useState([]);
    const [selectedAgents, setSelectedAgents] = useState([]);
    const _export = useRef(null);
    // const [currentUser] = useState({
    //     inEdit:true,
    //     username:null,
    //     name: null,
    //     region: null,
    //     phone: null,
    //     email: null,
    //     notes: null,
    // });
    const [showEditDialog, setShowEditDialog] = useState(false);

    const [showAgentDialog, setShowAgentDialog] = useState(false);
    const intl = useIntl();
    const cellWidth = 200;

    const downloadXls = () => {
        if (_export.current !== null) {
            _export.current.save();
        }
    }

    useEffect(() => loadAgents(), []);
    useEffect(() => setUsers(props.data), [props.data]);

    function saveClient(dataItem) {
        setLoading(true);

        const data = userGridData.map(user => !user.id && user);
        setUserGridData(data);
        let cleanedItem = Object.assign({}, dataItem)

        let url = BASEURI + '/api/dashboard/user/',
            options = {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
                body: JSON.stringify(cleanedItem)
            }
        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {
                //no token exists or all tokens are expired
                if (token === false) {
                    this.setState({ loggedin: false });
                    return false;
                }

                options.headers.Authorization = `Bearer  ${token}`;
                fetch(url, options)
                    .then((response) => {
                        return response.json();
                    })
                    .then((newUser) => {
                        setLoading(false);
                        if (newUser.message) {
                            alert(newUser.message)
                            setUserGridData(userGridData);
                        } else {
                            setShowEditDialog(false);
                            setUsers({
                                all: [...users.all, newUser],
                                today: [...users.today, newUser],
                                thisMonth: [...users.thisMonth, newUser],
                                inactive: [...users.inactive, newUser]
                            },
                                setUserGridData([newUser, ...userGridData.filter(user => user.id && user)]))
                        }
                    })
            })
    }

    // function enterClientEdit(dataItem) {
    //     setUserGridData(userGridData.map(user =>{
    //            if(user.id === dataItem.id){
    //             setSelectedAgents(dataItem.agents);
    //             return {...user, inEdit: true};
    //            } 
    //            else {
    //                return user;
    //             }
    //         }
    //         )
    //     )
    // }

    function updateClient(dataItem) {
        setLoading(true);
        // let selectedAgents = agents.map((agent)=> agent.id === dataItem.agent.id);
        let selectedAgents = dataItem.agents.map(agent => agent.id);
        const data = userGridData.map(user => {
            return user.id === dataItem.id ?
                { ...user, inEdit: undefined, agents: selectedAgents } : user
        });
        setUserGridData(data);

        let url = BASEURI + `/api/dashboard/user/${dataItem.id}/`,
            options = {
                method: 'PUT',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
            };
        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                //no token exists or all tokens are expired
                if (token === false) {
                    this.setState({ loggedin: false });
                    return false;
                }

                options.headers.Authorization = `Bearer  ${token}`;
                delete dataItem.agents;
                options.body = JSON.stringify((({
                    inEdit,
                    ...rest
                }) => ({
                    ...rest,
                    user: rest.user.id,
                    organization: (rest.organization && rest.organization.id) ?
                        rest.organization.id : rest.organization,
                    agents: selectedAgents
                }))(dataItem));

                // console.log(options);
                fetch(url, options)
                    .then((response) => {
                        return response.json();
                    })
                    .then((newUser) => {
                        setLoading(false);
                        setViewingUser(newUser);
                        //update original/base grid data
                        setUsers({
                            all: users.all.map(user =>
                                user.id === dataItem.id ?
                                    newUser : user
                            ),
                            today: users.today.map(user =>
                                user.id === dataItem.id ?
                                    newUser : user
                            ),
                            thisMonth: users.thisMonth.map(user =>
                                user.id === dataItem.id ?
                                    newUser : user
                            ),
                            inactive: users.inactive.map(user =>
                                user.id === dataItem.id ?
                                    newUser : user
                            )

                        });
                        setUserGridData(userGridData.map((user) => user.id === newUser.id ? { ...newUser, inEdit: undefined } : user));

                    })
                    .catch(error => {
                        console.error('Error:', error.code);
                        setLoading(false);

                        //reset current grid data back to original item
                        const originalUserItem = users.all.find(user => user.id === dataItem.id);
                        const data = userGridData.map(user => user.id === dataItem.id ? originalUserItem : user);
                        setUserGridData(data);

                    });
            })
            ;
    }

    function deleteClient(dataItem, newUser) {
        setDeleting(true);

        let url = BASEURI + `/api/dashboard/user/${dataItem.id}/`,
            options = {
                method: 'DELETE',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
            };
        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                //no token exists or all tokens are expired
                if (token === false) {
                    // this.setState({loggedin:false});
                    return false;
                }

                options.headers.Authorization = `Bearer  ${token}`;

                fetch(url, options)
                    .then((response) => {
                        if (!response.ok) {
                            throw new Error(response.statusText, response.status);
                        }
                        if (response.status !== 204)
                            return response.json();
                    })
                    .then(() => {
                        setDeleting(false);
                        setViewingUser(newUser);
                        setUsers({
                            all: users.all.filter(user =>
                                user.id !== dataItem.id
                            ),
                            today: users.today.filter(user =>
                                user.id !== dataItem.id
                            ),
                            thisMonth: users.thisMonth.filter(user =>
                                user.id !== dataItem.id
                            ),
                            inactive: users.inactive.filter(user =>
                                user.id !== dataItem.id
                            )
                        });

                        const data = userGridData.filter(user => user.id !== dataItem.id);
                        setUserGridData(data);

                        toggleEditDialog();

                    })
                    .catch(error => {
                        console.error('Error:', error);
                        setDeleting(false);
                    });
            })
            ;
    }

    function cancelClientEdit(dataItem) {
        if (!dataItem.id) {
            const data = userGridData.filter(user => user.id && user);
            setUserGridData(data);
        } else {
            const originalUserItem = users.all.find(user => user.id === dataItem.id);
            const data = userGridData.map(user => user.id === dataItem.id ? originalUserItem : user);
            setUserGridData(data);
        }
    }

    function onClientChange(event) {
        const data = userGridData.map(user =>
            user.id === event.dataItem.id ?
                {
                    ...user,
                    [event.field === 'user.username' ? 'username' : event.field]: event.value
                } : user
        );
        setUserGridData(data);
    }


    function setUserGrid(e, set) {
        if (set === 'Today')
            setUserGridData(users.today);
        else if (set === 'This Month')
            setUserGridData(users.thisMonth);
        else if (set === 'Inactive')
            setUserGridData(users.inactive);
        else
            setUserGridData(users.all);
        // executeRef(usersRef);
        e.preventDefault();
    }


    const handleAgentsChange = (e) => {
        setSelectedAgents(e.target.value);
    }

    const handleAgentModal = () => {
        showAgentDialog !== true ? setShowAgentDialog(true) : setShowAgentDialog(false);
    }

    function loadAgents() {
        let url = BASEURI + '/api/dashboard/agents/',
            options = {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
            }
        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {
                //no token exists or all tokens are expired
                if (token === false) {
                    this.setState({ loggedin: false });
                    return false;
                }

                options.headers.Authorization = `Bearer  ${token}`;
                fetch(url, options)
                    .then((response) => {
                        return response.json()
                    })
                    .then((allAgents) => setAgents(allAgents))
            })
    }



    const Commands = CommandCell({
        save: saveClient,
        edit: edit,
        update: updateClient,
        cancel: cancelClientEdit,
        editField: "inEdit",
    }, intl);

    const Agents = AgentCell({
        editField: "inEdit",
        selectedAgents: selectedAgents,
        allAgents: agents,
        handleAgentsChange: handleAgentsChange,
    })

    function pageChange(e) {
        setSkip(e.page.skip);
    }

    function toggleEditDialog() {
        setShowEditDialog(!showEditDialog)
    }

    function edit(dataItem) {
        toggleEditDialog();
        setViewingUser(dataItem);

    }

    const [OrgCell, setOrgCell] = useState();

    useEffect(() => {
        if (users.all.length > 0) {
            const enhancer = Base => props => {
                return props => <Base {...props} data={users.all.filter(u => u.is_organization)} />;
            };
            setOrgCell(enhancer(OrganizationCell));
        }
    }, [users.all]);

    return (
        <div ref={props.usersRef} className='mdl-cell mdl-grid--no-spacing mdl-cell--12-col mdl-shadow--2dp user-grid'>
            <ExcelExport
                data={Array.isArray(userGridData) && userGridData.map((item) => {
                    return {
                        ...item,
                        is_organization: item.is_organization ? 'ORG' : 'REG',
                        user: {
                            ...item.user,
                            last_login: item.user && item.user.last_login ? formatDate(item.user.last_login) : ''
                        },
                        agents: item.agents && item.agents.map(agentsItem => agentsItem.name).join()
                    }
                })}
                ref={_export}
                fileName="AgViewer Users">
                <ExcelExportColumn
                    title={intl.formatMessage({ id: 'app.admin.userGrid.user', defaultMessage: 'User' })}
                    field="user.username"
                    width={cellWidth} />

                <ExcelExportColumn
                    title={intl.formatMessage({ id: 'app.admin.userGrid.Name', defaultMessage: 'Name' })}
                    field='name'
                    width={cellWidth}
                />
                <ExcelExportColumn
                    title={intl.formatMessage({ id: 'app.admin.userGrid.lastLogin', defaultMessage: 'Last Login' })}
                    field='user.last_login'
                    width={cellWidth}
                />
                <ExcelExportColumn
                    title={intl.formatMessage({ id: 'app.admin.userGrid.country', defaultMessage: 'Country' })}
                    field='country'
                    width={cellWidth}
                />
                <ExcelExportColumn
                    title={intl.formatMessage({ id: 'app.admin.userGrid.region', defaultMessage: 'Region' })}
                    field='region'
                    width={cellWidth}
                />
                <ExcelExportColumn
                    title={intl.formatMessage({ id: 'app.admin.userGrid.email', defaultMessage: 'Email' })}
                    field='email'
                    width={cellWidth}
                />
                <ExcelExportColumn
                    title={intl.formatMessage({ id: 'app.admin.userGrid.phone', defaultMessage: 'Phone' })}
                    field='phone'
                    width={cellWidth}
                />
                <ExcelExportColumn
                    title={intl.formatMessage({ id: 'app.admin.userGrid.organization', defaultMessage: 'Organization' })}
                    field='organization.user.username'
                    width={cellWidth}
                />
                <ExcelExportColumn
                    title={intl.formatMessage({ id: 'app.admin.userGrid.accType', defaultMessage: 'Acc Type' })}
                    field='is_organization'
                    width={cellWidth}
                />
                <ExcelExportColumn
                    cell={Agents}
                    title={intl.formatMessage({ id: 'app.admin.userGrid.agents', defaultMessage: 'Agents' })}
                    field='agents'
                    width={cellWidth}
                />
            </ExcelExport>
            <KGrid data={filterBy(orderBy(userGridData, sort), filter).slice(skip, skip + pageSize)}
                scrollable={'virtual'}
                className='!h-[600px]'
                sortable
                sort={sort}
                onSortChange={e => setSort(e.sort)}
                filterable
                filter={filter}
                onFilterChange={e => {
                    setFilter(e.filter);
                    setSkip(0);
                }}
                editField='inEdit'
                onItemChange={onClientChange}
                skip={skip}
                total={userGridData.length}
                pageSize={pageSize}
                onPageChange={pageChange}
                rowHeight={40}
            >
                <GridNoRecords>
                    {intl.formatMessage({ id: 'app.admin.graph_grid.no_data', defaultMessage: 'No Records Available' })}
                </GridNoRecords>
                <GridToolbar>
                    <div className='mdl-grid mdl-grid--no-spacing mdl-cell--12-col'>
                        <div className='mdl-cell mdl-cell--4-col ag-grid-title'>
                            {intl.formatMessage({ id: 'app.admin.userGrid.users', defaultMessage: 'Users' })}
                        </div>
                        <div className='ag-grid-link-button--ctn mdl-cell mdl-cell--8-col'>
                            <button className="mdl-button mdl-js-button mdl-button--primary" style={{ marginRight: 5 }} onClick={downloadXls}>{intl.formatMessage({ id: 'app.admin.stationGrid.download', defaultMessage: 'Download' })}</button>
                            <button className='mdl-button mdl-js-button mdl-button--primary user-grid-button' onClick={toggleEditDialog}>{intl.formatMessage({ id: 'app.admin.userGrid.newUser', defaultMessage: 'New User' })}</button>
                            {props.isAdmin &&
                                <button className='mdl-button mdl-js-button mdl-button--primary user-grid-button' onClick={handleAgentModal}>{intl.formatMessage({ id: 'app.admin.userGrid.agents', defaultMessage: 'Agents' })}</button>
                            }
                            <button className='ag-link-button ag-link-button--green-underline' onClick={(e) => setUserGrid(e, 'All')}>{intl.formatMessage({ id: 'app.admin.userGrid.all', defaultMessage: 'All' })}</button>
                            <button className='ag-link-button ag-link-button--green-underline' onClick={(e) => setUserGrid(e, 'Today')}>{intl.formatMessage({ id: 'app.admin.userGrid.today', defaultMessage: 'Today' })}</button>
                            <button className='ag-link-button ag-link-button--green-underline' onClick={(e) => setUserGrid(e, 'This Month')}>{intl.formatMessage({ id: 'app.admin.userGrid.thisMonth', defaultMessage: 'This Month' })}</button>
                            <button className='ag-link-button ag-link-button--green-underline' onClick={(e) => setUserGrid(e, 'Inactive')}>{intl.formatMessage({ id: 'app.admin.userGrid.inactive', defaultMessage: 'Inactive' })}</button>
                        </div>
                    </div>

                </GridToolbar>
                <Column
                    title={intl.formatMessage({ id: 'app.admin.userGrid.user', defaultMessage: 'User' })}
                    field="user.username"
                    cell={UserNameCell}
                    width={cellWidth}
                    className='!overflow-ellipsis !whitespace-nowrap'
                />
                <Column
                    title={intl.formatMessage({ id: 'app.admin.userGrid.Name', defaultMessage: 'Name' })}
                    field='name'
                    width={cellWidth}
                    className='!overflow-ellipsis !whitespace-nowrap'
                />
                <Column
                    title={intl.formatMessage({ id: 'app.admin.userGrid.lastLogin', defaultMessage: 'Last Login' })}
                    field='user.last_login'
                    cell={DateCell}
                    width={cellWidth}
                />
                <Column
                    title={intl.formatMessage({ id: 'app.admin.userGrid.country', defaultMessage: 'Country' })}
                    field='country'
                    width={cellWidth}
                    className='!overflow-ellipsis !whitespace-nowrap'
                />
                <Column
                    title={intl.formatMessage({ id: 'app.admin.userGrid.region', defaultMessage: 'Region' })}
                    field='region'
                    width={cellWidth}
                    className='!overflow-ellipsis !whitespace-nowrap'
                />
                <Column
                    title={intl.formatMessage({ id: 'app.admin.userGrid.email', defaultMessage: 'Email' })}
                    field='email'
                    width={cellWidth}
                    className='!overflow-ellipsis !whitespace-nowrap'
                />
                <Column
                    title={intl.formatMessage({ id: 'app.admin.userGrid.phone', defaultMessage: 'Phone' })}
                    field='phone'
                    width={cellWidth}
                    className='!overflow-ellipsis !whitespace-nowrap'
                />
                {/* <Column 
                        title={intl.formatMessage({id:'app.admin.userGrid.notes', defaultMessage:'Notes'})}
                        field='notes'
                        width={200}
                    /> */}
                {/* {props.isAdmin && 
                        <Column 
                            title={intl.formatMessage({id:'app.admin.userGrid.salesTax', defaultMessage:'Sales Tax'})}
                            field='sales_tax'
                            editor="numeric"
                            width={120}
                        />
                    } */}
                {props.isAdmin &&
                    <Column
                        title={intl.formatMessage({ id: 'app.admin.userGrid.organization', defaultMessage: 'Organization' })}
                        field='organization.user.username'
                        cell={OrgCell}
                        width={cellWidth}
                    />
                }
                {props.isAdmin &&
                    <Column
                        title={intl.formatMessage({ id: 'app.admin.userGrid.accType', defaultMessage: 'Acc Type' })} field='is_organization'
                        filter={'boolean'}
                        filterCell={UserTypeFilterCell}
                        cell={UserTypeCell}
                        width={120}
                    />
                }
                {/* <Column cell={PaymentCell} field='Payment' filterable={false} sortable={false} /> */}
                {props.isAdmin &&
                    <Column
                        cell={Agents}
                        title={intl.formatMessage({ id: 'app.admin.userGrid.agents', defaultMessage: 'Agents' })}
                        field='agents'
                        filterable={false}
                        sortable={false}
                        width={cellWidth}
                    />
                }
                <Column cell={Commands} filterable={false} sortable={false} />

            </KGrid>

            {(props.isAdmin || props.isOrganization) &&
                <EditUserModal
                    isAdmin={props.isAdmin}
                    users={users.all}
                    organizationUsers={users.all.filter(user => user.is_organization && user)}
                    show={showEditDialog}
                    save={saveClient}
                    update={updateClient}
                    delete={deleteClient}
                    loading={loading}
                    deleting={deleting}
                    viewingUser={viewingUser}
                    setViewingUser={setViewingUser}
                    onClose={toggleEditDialog}
                    agents={agents}

                />
            }
            {(props.isAdmin && showAgentDialog) &&
                <AgentDialog
                    intl={intl}
                    setAgents={setAgents}
                    handleAgentModal={handleAgentModal}
                    agents={agents}
                />
            }
        </div>
    );
}
export default UserGrid;

function UserNameCell(props) {
    function handleChange(event) {
        props.onChange({
            dataItem: dataItem,
            field: props.field,
            syntheticEvent: event.syntheticEvent,
            value: event.value
        })

    }

    const { dataItem } = props;
    return (
        dataItem.inEdit && !dataItem.id ?
            <td>
                <Input name="username" onChange={handleChange} />
            </td> :
            <td>
                {dataItem.user ? dataItem.user.username : dataItem.username}
            </td>
    );
}

function AgentDialog(props) {

    const [currentAgent] = useState({
        name: null,
        last_name: null,
        email: null,
        phone: null,
        inEdit: true,
    });

    function addAgentRecord() {
        props.setAgents([currentAgent, ...props.agents]);
    }

    function onAgentItemChange(event) {
        const data = props.agents.map(agent => agent.inEdit === event.dataItem.inEdit ? { ...agent, [event.field]: event.value } : agent)
        props.setAgents(data);
    }

    function saveAgent(dataItem) {
        if (!dataItem.name) {
            alert(props.intl.formatMessage({ id: "app.admin.agentDialog.message", defaultMessage: "Please enter a name. name field is required!" }))
            return
        }
        let cleanedItem = Object.assign({}, dataItem);
        delete cleanedItem.inEdit;


        let url = BASEURI + '/api/dashboard/agents/',
            options = {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
                body: JSON.stringify(cleanedItem)
            }
        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {
                //no token exists or all tokens are expired
                if (token === false) {
                    this.setState({ loggedin: false });
                    return false;
                }

                options.headers.Authorization = `Bearer  ${token}`;
                fetch(url, options)
                    .then((response) => {
                        return response.json();
                    })
                    .then((agent) => {
                        if (agent.message) {
                            alert(agent.message);
                        } else {
                            props.setAgents([agent, ...props.agents.filter(item => item.id && item)])
                        }
                    });
            })


    }

    function deleteAgent(dataItem) {

        if (!window.confirm(props.intl.formatMessage({ id: "app.admin.agentDialog.confirmMessage", defaultMessage: 'Are you sure want to delete {name}?' }, { name: dataItem.name })))
            return

        let url = BASEURI + '/api/dashboard/agents/',
            options = {
                method: 'DELETE',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
                body: JSON.stringify(dataItem.id)
            }
        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {
                //no token exists or all tokens are expired
                if (token === false) {
                    this.setState({ loggedin: false });
                    return false;
                }

                options.headers.Authorization = `Bearer  ${token}`;
                fetch(url, options)
                    .then((response) => {
                        return response.json();
                    })
                    .then((allAgents) => props.setAgents(allAgents));
            })
    }

    const cancel = (dataItem) => {
        if (dataItem.id) {
            const data = props.agents.map(agent => agent.id === dataItem.id ? { ...agent, inEdit: false } : agent);
            props.setAgents(data);
        } else {
            const data = props.agents.filter(agent => agent.id && agent.id);
            props.setAgents(data);
        }

    }

    function edit(dataItem) {
        const data = props.agents.map(agent => agent.id === dataItem.id ? { ...agent, inEdit: true } : agent)
        props.setAgents(data);
    }

    function put(dataItem) {

        let cleanedItem = Object.assign({}, dataItem);
        delete cleanedItem.inEdit
        let url = BASEURI + '/api/dashboard/agents/',
            options = {
                method: 'PUT',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
                body: JSON.stringify(cleanedItem)
            }
        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {
                //no token exists or all tokens are expired
                if (token === false) {
                    this.setState({ loggedin: false });
                    return false;
                }

                options.headers.Authorization = `Bearer  ${token}`;
                fetch(url, options)
                    .then((response) => {
                        return response.json();
                    })
                    .then((agent) => {
                        let data = props.agents.map(item => item.id === agent.id ? { ...item, inEdit: false } : item);
                        props.setAgents(data)
                    }
                    );
            })

    }


    const Commands = AgentCommands({
        save: saveAgent,
        deleteAgent: deleteAgent,
        cancel: cancel,
        edit: edit,
        update: put,
        intl: props.intl
    });


    return (
        <Modal>
            <Dialog onClose={props.handleAgentModal} >
                <KGrid
                    data={props.agents}
                    onItemChange={onAgentItemChange}
                    editField="inEdit"
                    scrollable={'scrollable'}
                >
                    <GridToolbar>
                        <div className='mdl-grid mdl-grid--no-spacing'>
                            <div className='mdl-cell mdl-cell--6-col ag-grid-title'>
                                {props.intl.formatMessage({ id: "app.admin.agentDialog.allAgents", defaultMessage: "All Agents" })} - {props.intl.formatNumber(props.agents.length)}
                            </div>
                            <div className='ag-grid-link-button--ctn mdl-cell mdl-cell--6-col modal-from-row'>
                                <button
                                    className='mdl-button mdl-button--colored'
                                    onClick={addAgentRecord}
                                >
                                    {props.intl.formatMessage({ id: "app.admin.agentDialog.addAgent", defaultMessage: "Add Agent" })}
                                </button>
                            </div>
                        </div>
                    </GridToolbar>
                    <Column field="name" title={props.intl.formatMessage({ id: "app.admin.agentDialog.name", defaultMessage: "Name" })} editable={true} editor="text" />
                    <Column field="last_name" title={props.intl.formatMessage({ id: "app.admin.agentDialog.lastName", defaultMessage: "Last Name" })} editable={true} editor="text" />
                    <Column field="email" title={props.intl.formatMessage({ id: "app.admin.agentDialog.email", defaultMessage: "Email" })} editable={true} editor="text" />
                    <Column field="phone" title={props.intl.formatMessage({ id: "app.admin.agentDialog.Phone", defaultMessage: "Phone" })} editable={true} editor="text" />
                    <Column cell={Commands} title={props.intl.formatMessage({ id: "app.admin.agentDialog.action", defaultMessage: "Actions" })} filterable={false} sortable={false} />
                </KGrid>
                <DialogActionsBar>
                    <button className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md" onClick={props.handleAgentModal} >{props.intl.formatMessage({ id: "app.admin.agentDialog.close", defaultMessage: "Close" })}</button>
                </DialogActionsBar>
            </Dialog>
        </Modal>
    );
}

function AgentCommands({ save, deleteAgent, cancel, edit, update, intl }) {
    return props => {
        // render(){
        const { dataItem } = props;

        return (
            dataItem.inEdit ?
                <td>{
                    dataItem.id ?
                        <button onClick={() => { update(dataItem) }} className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md k-primary">{intl.formatMessage({ id: "app.admin.agentDialog.save", defaultMessage: "Save" })}</button>
                        : <button onClick={() => { save(dataItem) }} className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md k-primary">{intl.formatMessage({ id: "app.admin.agentDialog.save", defaultMessage: "Save" })}</button>
                }
                    <button onClick={() => { cancel(dataItem) }} className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md">{intl.formatMessage({ id: "app.admin.agentDialog.cancel", defaultMessage: "Cancel" })}</button>
                </td>
                :
                <td>
                    <button onClick={() => { edit(dataItem) }} className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md k-primary">{intl.formatMessage({ id: "app.admin.agentDialog.edit", defaultMessage: "Edit" })}</button>
                    <button onClick={() => { deleteAgent(dataItem) }} className="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md k-warning">{intl.formatMessage({ id: "app.admin.agentDialog.delete", defaultMessage: "Delete" })}</button>
                </td>
        );
        // }
    }
}
