import React, { useEffect } from 'react';
import Highcharts from 'highcharts';
import { useIntl } from 'react-intl';

const markerFilters = {
    'All': 'All',
    'Stress severo': 'RED',
    'Inicia stress': 'YELLOW',
    'Adecuado': 'GREEN',
    'Exceso de agua': 'BLUE'
}

function PiesContainer(props) {
    return (
        <div className="mdl-grid mdl-grid--no-spacing pie-box--container" >

            <div className="mdl-cell mdl-cell--12-col">
                <div className='mdl-grid'>
                    {props.children}
                </div>
            </div>

        </div>
    );
}


function PieBox(props) {
    const intl = useIntl();
    return (props.insideMap ?
        <Pie
            isGraphPieBox={props.isGraphPieBox}
            setGridData={props.setGridData}
            data={props.data}
            name={props.name}
            insideMap={props.insideMap}
            setFilter={props.setFilter} /> :
        <div className="w-full lg:w-[50%] max-w-[550px] mt-4">
            <div>
                {props.name} {intl.formatMessage({ id: 'app.reports.piebox', defaultMessage: 'Status' })}
            </div>

            <div className='mdl-cell mdl-cell--12-col pie-box--content h-[270px] lg:h-[200px]'>
                <Pie
                    total={props.all}
                    isGraphPieBox={props.isGraphPieBox}
                    data={props.data}
                    name={props.name}
                    setGridData={props.setGridData}
                    insideMap={props.insideMap}
                    setFilter={props.setFilter}
                />
            </div>
        </div >
    );
}


function Pie(props) {

    let filterValue = 'All';

    function handleClick(e) {
        const name = e.point.id;
        props.setGridData(e, name);

        if (filterValue === name) {
            filterValue = 'All';
        } else {
            filterValue = name;
        }
        props.setFilter(markerFilters[filterValue])
    }
    useEffect(() => {

        Highcharts.chart(props.name, {
            chart: {
                backgroundColor: props.insideMap && null,
                type: 'pie',
                events: {
                    load: function () {
                        if (props.total)
                            this.renderer.text(`<b>Total:</b> ${props.total}`, 1, 30)
                                .css({
                                    fontSize: '12px',
                                    color: '#333'
                                })
                                .add();
                    }
                }

            },
            credits: {
                enabled: false
            },
            title: null,
            tooltip: {
                pointFormat: `Total: <b>{point.custom.total: .1f}</b> <br>{point.name}: <b>{point.y: .1f} ({point.custom.percentage: .1f}%) </b>`
            },
            legend: {
                align: window?.screen?.width > 800 ? 'right' : 'center',       // Aligns the legend to the right
                verticalAlign: window?.screen?.width > 800 ? 'middle' : 'bottom', // Vertically aligns it to the center of the chart
                labelFormat: '{name}: <span class="text-xs">{y} ({custom.percentage:.1f}%)</span>',
            },
            plotOptions: {

                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    size: props.insideMap ? '70%' : null,
                    dataLabels: {
                        style: {
                            fontSize: 15
                        },
                        enabled: props.insideMap ? true : false,
                        // enabled: false
                        format: '<b>{point.name}</b><br>{point.custom.percentage:.1f} %',
                        distance: 25

                    },
                    showInLegend: props.insideMap ? false : true
                }
            },
            series: [{
                name: 'Status',
                colorByPoint: true,
                data: props.data,
                events: props.isGraphPieBox && {
                    click: handleClick
                }
            }],
            exporting: {
                enabled: false
            }
        });

    }, [props.data]);

    return (
        <div id={props.name} style={{ width: '100%', height: '100%' }}>

        </div>
    );
}


export default PieBox;
export { PiesContainer, Pie };