import React, { useState, useEffect, useMemo, createContext } from 'react';
import { getAuthToken, BASEURI, advancedFilter } from '../utils';
import { useIntl } from 'react-intl';
import IrrigationScheduler from './Scheduler';
import IrrigationEvents from './IrrigationEvents';
import IrrigationReport from './summary/IrrigationReport';

export const StationContext = createContext({});
export const TimezoneContext = createContext({})

const TabLayout = () => {
    const intl = useIntl();
    const [stations, setStations] = useState([]);
    const [stationsCopy, setStationsCopy] = useState([]);
    const [sectors, setSectors] = useState([]);
    const [station, setStation] = useState({});
    const [tab, setTab] = useState('schedule')
    const [timezone, setTimezone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);
    // Set default browser timezone as default value
    const [timezones, setTimezones] = useState([
        Intl.DateTimeFormat().resolvedOptions().timeZone
    ]);

    const getTimezone = () => {
        setTimezones((prevState) => new Set([...prevState, ...stations.map(item => item?.details?.timezone)]));
    }

    // Set Timezone of whole scheduler 
    useEffect(() => { getTimezone() }, [stations])

    useEffect(() => {
        loadDevices();
    }, [])

    useEffect(() => {
        loadDeviceSectors(station.serial, station.api === "Wiseconn" ? 'zones' : 'sectors');
    }, [station])

    const loadDeviceSectors = (serial, type) => {

        let url = BASEURI + `/api/scheduler/${type}/${serial}`,
            options = {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
            };

        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                //no token exists or all tokens are expired
                if (token === false) {
                    return false;
                }

                options.headers.Authorization = `Bearer  ${token}`;

                fetch(url, options)
                    .then((response) => {
                        return response.json();
                    })

                    .then((sectors_list) => {
                        setSectors(sectors_list);
                    })

                    .catch(error => {
                        console.error('Error:', error.code);
                    });
            });
    }

    function handleStationFilterChange(e) {

        advancedFilter(
            e.filter.value,
            stationsCopy,
            setStations,
            ['name', 'serial'])
    }

    const loadDevices = () => {

        let url = BASEURI + `/api/scheduler/devices/`,
            options = {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
            };

        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                //no token exists or all tokens are expired
                if (token === false) {
                    return false;
                }

                options.headers.Authorization = `Bearer  ${token}`;

                fetch(url, options)
                    .then((response) => {
                        return response.json();
                    })

                    .then((devices_list) => {
                        setStations(devices_list);
                        setStationsCopy(devices_list);

                    })

                    .catch(error => {
                        console.error('Error:', error.code);
                    });
            });
    }

    const scheduler = useMemo(() => <IrrigationScheduler />, [])
    const events = useMemo(() => <IrrigationEvents />, [timezone])
    const reports = useMemo(() => <IrrigationReport />, [])

    return (
        <TimezoneContext.Provider value={{ timezone, timezones, setTimezone }}>
            <StationContext.Provider value={{
                stations,
                setStations,
                station,
                setStation,
                stationsCopy,
                setStationsCopy,
                handleStationFilterChange,
                sectors
            }}>
                <div>
                    <div className='pt-5'>
                        {/* <div className="sm:hidden">
                    <label for="Tab" className="sr-only">Tab</label>

                    <select id="Tab" className="w-full rounded-md border-gray-200">
                        <option>Calendar</option>
                        <option>Events</option>
                        <option select>Reports</option>
                    </select>
                </div> */}

                        {/* <div class="hidden sm:block"> */}
                        <div>
                            <div className="border-b border-gray-200">
                                <nav className="-mb-px flex justify-center gap-6" aria-label="Tabs">
                                    <button onClick={() => setTab('reports')} className={`shrink-0 border-b-2  px-1 pb-4 text-lg font-medium ${tab === 'reports' ? 'border-green-600 text-green-600' : 'text-gray-700 '} `} aria-current="page">
                                        {intl.formatMessage({ id: 'app.layout.report', defaultMessage: 'Reports' })}
                                    </button>
                                    <button onClick={() => setTab('schedule')} className={`shrink-0 border-b-2  px-1 pb-4 text-lg font-medium ${tab === 'schedule' ? 'border-green-600 text-green-600' : 'text-gray-700  '} `}>
                                        {intl.formatMessage({ id: 'app.scheduler.layout.calendar', defaultMessage: 'Calendar' })}
                                    </button>
                                    <button onClick={() => setTab('events')} className={`shrink-0 border-b-2  px-1 pb-4 text-lg font-medium ${tab === 'events' ? 'border-green-600 text-green-600' : 'text-gray-700 '} `}>
                                        {intl.formatMessage({ id: 'app.scheduler.layout.events', defaultMessage: 'Events' })}
                                    </button>
                                </nav>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div style={{ display: tab === 'reports' ? 'block' : 'none' }} > {reports} </div>
                        <div style={{ display: tab === 'schedule' ? 'block' : 'none' }} > {scheduler} </div>
                        <div style={{ display: tab === 'events' ? 'block' : 'none' }} > {events} </div>
                    </div>
                </div>
            </StationContext.Provider >
        </TimezoneContext.Provider>
    );
};

export default TabLayout;
