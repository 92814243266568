import React, { useState, useEffect } from 'react';
import { BASEURI, advancedFilter, getAuthToken } from '../utils';
import { DropDownList, MultiSelect } from '@progress/kendo-react-dropdowns';
import { DateTimePicker } from "@progress/kendo-react-dateinputs";
import loading from '../loading.svg';
import { useIntl } from 'react-intl';
import ConfirmDeleteDialog from '../general/ConfirmDeleteDialog';


function DeleteRecords(props) {
    const intl = useIntl();

    const [station, setStation] = useState();
    const [stations, setStations] = useState();
    const [stationsCopy, setStationsCopy] = useState();
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [numberOfRecords, setNumberOfRecords] = useState(0);
    const [numberOfSensors, setNumberOfSensors] = useState(0);
    const [message, setMessage] = useState();
    const [errorMessage, setErrorMessage] = useState();
    const [stationsSensors, setStationsSensors] = useState([]);
    const [filteredSensors, setFilteredSensors] = useState([]);
    const [showConfrimDialog, setShowConfrimDialog] = useState(false);
    const [sensors, setSensors] = useState([]);
    const [deleting, setDeleteing] = useState(false);

    function handleDateChange(e) {
        if (e.target.name === 'start_date') {
            setStartDate(e.target.value);
        } else if (e.target.name === 'end_date') {
            setEndDate(e.target.value);
        }
    }

    const loadStations = () => {

        let url = BASEURI + '/api/dashboard/station/',
            options = {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },
            };

        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                //no token exists or all tokens are expired
                if (token === false) {
                    this.setState({ loggedin: false });
                    return false;
                }

                options.headers.Authorization = `Bearer  ${token}`;

                fetch(url, options)
                    .then((response) => {
                        return response.json();
                    })
                    .then((stations) => {
                        setStations(stations);
                        setStationsCopy(stations);
                    })
                    .catch(error => {
                        console.error('Error:', error.code);
                    });
            });
    }

    useEffect(() => { loadStations() }, [])

    function loadSensors(serial) {
        let url = `${BASEURI}/api/stations/sensors/${serial}/`,
            options = {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                }
            };
        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                options.headers.Authorization = `Bearer  ${token}`;

                fetch(url, options)
                    .then((response) => {
                        return response.json();
                    })
                    .then((sensors) => {
                        setStationsSensors(sensors);
                        setFilteredSensors(sensors);
                    })
            });
    }


    function handleStationChange(e) {
        setMessage('');
        setStation(e.target.value);
        loadSensors(e.target.value.serial);

    }

    function handleSensorChange(e) {
        setMessage('');
        setSensors(e.target.value);
    }

    function removeData() {
        setDeleteing(true);
        let url = `${BASEURI}/api/tools/records/`,

            options = {
                method: 'DELETE',
                body: JSON.stringify({
                    station_serial: station.serial,
                    start_date: Math.floor(startDate.getTime() / 1000),
                    end_date: Math.floor(endDate.getTime() / 1000),
                    sensors: sensors.map(item => item.port)
                }),
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                }
            };

        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                options.headers.Authorization = `Bearer  ${token}`;

                fetch(url, options)
                    .then((response) => response.json())
                    .then((data) => {
                        if (data['success']) {
                            setMessage(intl.formatMessage({ id: 'app.tools.deleteRecords.successMessage', defaultMessage: 'Records successfully deleted!' }));
                            setDeleteing(false);
                        }

                        setShowConfrimDialog(false);

                    })
                    .catch(error => console.log(error));
            });
    }

    function getStatistics() {
        setDeleteing(true);
        let url = `${BASEURI}/api/tools/records/statistics/${station.serial}/` +
            `?start_date=${Math.floor(startDate.getTime() / 1000)}&end_date=${Math.floor(endDate.getTime() / 1000)}` +
            `&sensors=${sensors.map(item => item.port)}`,

            options = {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                }
            };

        getAuthToken()
            .then(token => token)
            .catch(token => token)
            .then(token => {

                options.headers.Authorization = `Bearer  ${token}`;

                fetch(url, options)
                    .then((response) => response.json())
                    .then((data) => {
                        setNumberOfRecords(data['number_of_records']);
                        setNumberOfSensors(data['number_of_sensors']);
                        setDeleteing(false);

                        if (data?.error_message) {
                            setErrorMessage(data?.error_message)
                            return
                        }
                        setErrorMessage('')

                        if (data['number_of_records'] === 0)
                            setMessage(intl.formatMessage({ id: 'app.tools.deleteRecords.noRecords', defaultMessage: 'There is no records for selected range!' }));
                        else
                            setShowConfrimDialog(true);
                    })
                    .catch(error => console.log(error));
            });
    }

    function handleStationFilterChange(e) {
        advancedFilter(e.filter.value, stationsCopy, setStations, ['name', 'serial'])
    }

    function handleSensorFilterChange(e) {
        advancedFilter(e.filter.value, stationsSensors, setFilteredSensors, ['name', 'port'])
    }


    function closeConfimDialog() {
        setShowConfrimDialog(false);
    }

    return (

        <div className="mdl-grid" style={{ justifyContent: 'center' }}>
            {showConfrimDialog &&
                <ConfirmDeleteDialog
                    message={intl.formatMessage(
                        {
                            id: 'app.chart.deleteChart.recordsDeleteMessage',
                            defaultMessage: "[{records}] Records will be deleted from [{sensors}] sensor(s). Type {delete} below to confirm."
                        },
                        { delete: <span style={{ color: 'red' }}>DELETE</span>, records: numberOfRecords, sensors: numberOfSensors }
                    )}
                    deleting={deleting}
                    onClose={closeConfimDialog}
                    confrimRecordDeletion={true}
                    remove={removeData}
                />}
            <div className="mdl-cell mdl-cell--8-col">
                <div className='mdl-grid copy-dash-ctn'>
                    <div className='mdl-cell mdl-cell--6-col mdl-cell--4-col-tablet mdl-cell--4-col-phone'>
                        <DropDownList
                            name='station'
                            label={intl.formatMessage({ id: 'app.tools.deleteRecords.station', defaultMessage: 'STATION' })}
                            data={stations}
                            value={station}
                            textField="name"
                            onChange={handleStationChange}
                            filterable={true}
                            onFilterChange={handleStationFilterChange}
                            required={true}
                        />
                        {!station && <div style={{ color: 'red' }}>{intl.formatMessage({
                            id: 'app.tools.deleteRecords.stationValidationMessage',
                            defaultMessage: 'Please select a station!'
                        })}</div>}
                    </div>
                    <div className='mdl-cell mdl-cell--6-col mdl-cell--4-col-tablet mdl-cell--4-col-phone'>
                        <MultiSelect
                            name='sensors'
                            label={intl.formatMessage({ id: 'app.tools.deleteRecords.sensors', defaultMessage: 'SENSOR(S)' })}
                            data={filteredSensors}
                            value={sensors}
                            textField="name"
                            onChange={handleSensorChange}
                            filterable={true}
                            onFilterChange={handleSensorFilterChange}
                            style={{ height: 'auto' }}
                        />
                    </div>
                    <div className='mdl-cell mdl-cell--6-col mdl-cell--4-col-tablet mdl-cell--4-col-phone'>
                        <div>{intl.formatMessage({ id: 'app.tools.deleteRecords.startDate', defaultMessage: 'START DATE' })}</div>
                        <DateTimePicker
                            name='start_date'
                            onChange={handleDateChange}
                            value={startDate} />

                    </div>
                    <div className='mdl-cell mdl-cell--6-col mdl-cell--4-col-tablet mdl-cell--4-col-phone'>
                        <div>{intl.formatMessage({ id: 'app.tools.deleteRecords.end_date', defaultMessage: 'END DATE' })}</div>
                        <DateTimePicker
                            name='end_date'
                            onChange={handleDateChange}
                            value={endDate} />
                    </div>
                    <div className='mdl-cell mdl-cell--6-col mdl-cell--4-col-tablet mdl-cell--4-col-phone'></div>
                    <div className='mdl-cell mdl-cell--6-col mdl-cell--4-col-tablet mdl-cell--4-col-phone'>
                        <button
                            style={{ marginTop: '15px', width: '150px' }}
                            className="mdl-button mdl-js-button mdl-button--raised"
                            onClick={() => { getStatistics(false) }}
                            disabled={!station}>
                            {deleting ?
                                <img style={{ color: 'gray', width: '25px', marginLeft: 'auto', marginRight: 'auto' }} src={loading} alt='loading' /> :
                                intl.formatMessage({ id: 'app.tools.deleteRecords.delete', defaultMessage: 'DELETE' })}
                        </button>
                    </div>
                </div>
                <span style={{ color: 'green' }}>{message}</span>
                <span style={{ color: 'red' }}>{errorMessage}</span>
            </div>
        </div>
    )
}

export default DeleteRecords;